import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  EFooterLayout,
  MarketStats as Stats,
  MarketStatsMarket,
} from "@organic-return/foundation-react/src/components/MarketStats/MarketStats"
import { BackgroundImage } from "@organic-return/foundation-react/src/components/Media"
import { EWsrvTransform } from "@organic-return/foundation-react/src/lib"

export const MarketStats = ({ module }: any) => {
  const data = useStaticQuery(graphql`
    query MarketStatsQuery {
      agilitySiteConfiguration {
        marketStatsJSON
      }
      allAgilityCommunity {
        nodes {
          marketStatsJSON
          customFields {
            name
          }
          path
          contentID
        }
      }
    }
  `)

  const allMarketStats = JSON.parse(
    data.agilitySiteConfiguration?.marketStatsJSON || "[]"
  )

  let communitiesList = data.allAgilityCommunity.nodes
  if (module.customFields.communitiesToShow && module.customFields.communitiesToShow.length > 0) {
    communitiesList = module.customFields.communitiesToShow.map((community: any) => {
      return data.allAgilityCommunity.nodes?.find((item: any) => item.contentID == community.contentID)
    })
  }

  const fields = module.customFields
  let markets: MarketStatsMarket[] = []
  allMarketStats.markets && markets.push(allMarketStats.markets[0])
  fields.showCommunities &&
    communitiesList.forEach((node: any) => {
      const statsProps = JSON.parse(node.marketStatsJSON)
      statsProps?.markets && (markets = [...markets, ...statsProps.markets])
    })

  let links: any[] = []
  if (fields.link?.href || fields.link2?.href) {
    fields.link?.href && links.push({
      href: fields.link.href,
      label: fields.link.text,
    })

    fields.link2?.href && links.push({
      href: fields.link2.href,
      label: fields.link2.text,
    })
  }

  links.push({
    label: "Learn More",
    url: "",
  })

  const footerLayout = fields.footerLayout === "column" ? EFooterLayout.column : EFooterLayout.centered

  const stats = (
    <Stats
      markets={markets}
      title={fields.title}
      className="mx-auto w-full max-w-screen-2xl"
      links={links}
      linkFunc={props => (
        <Link to={props.href ?? ""} className={props.className} key={props.key}>
          {props.children}
        </Link>
      )}
      footerLayout={footerLayout}
      linkAsButton={fields.linkAsButton === "true"}
      footerCopy={fields.footerText}
      useMarketRealatedUrl={true}
    />
  )

  let blockHeightClass = "h-fit"
  fields.height == "screen" && (blockHeightClass = "h-fit md:min-h-screen")

  if (!markets.length) return <></>
  return (
    <>
      {fields.backgroundImage?.url && (
        <BackgroundImage
          src={fields.backgroundImage?.url}
          className={`text-white flex justify-center w-full py-10 ${blockHeightClass}`}
          sizesAndAspect={[
            {
              width: 420,
              aspect: EWsrvTransform.cover2x6,
            },
            {
              media: "min-width:420px",
              width: 640,
              aspect: EWsrvTransform.cover2x6,
            },
            {
              media: "min-width:640px",
              width: 768,
              aspect: EWsrvTransform.cover3x2,
            },
            {
              media: "min-width:768px",
              width: 1024,
              aspect: EWsrvTransform.cover3x2,
            },
            {
              media: "min-width:1024px",
              width: 1280,
              aspect: EWsrvTransform.cover16x9,
            },
            {
              media: "min-width:1280px",
              width: 1440,
              aspect: EWsrvTransform.cover16x9,
            },
          ]}
        >
          {stats}
        </BackgroundImage>
      )}
      {!fields.backgroundImage?.url && stats}
    </>
  )
}

export default MarketStats
